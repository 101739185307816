import { ModalBase } from "../modal_base";

export class ListResolveModal extends ModalBase {
    constructor() {
        super('list_resolve_modal');
    }

    setupModalView($modalTriggerBtn, additionalData) {
        if(!additionalData.resolveType) {
            console.warn('No resolve type specified.');
            return;
        }

        if(app.URI[2] === 'veh_def' && $modalTriggerBtn.hasClass('btn-resolve')) {
            remodal_open_check_monitoring(this.MODAL_CONTAINER_EL, $modalTriggerBtn);
        }
        this.MODAL_CONTAINER_EL.removeClass('btn-pending').removeClass('btn-monitoring').removeClass('btn-resolve');

        switch(additionalData.resolveType) {
            case 'pending':
                this.MODAL_CONTAINER_EL.addClass('btn-pending');
                break;
            case 'monitoring':
                this.MODAL_CONTAINER_EL.addClass('btn-monitoring');
                break;
            case 'unresolve':
                this.MODAL_CONTAINER_EL.addClass('btn-resolve');
                break;
            case 'resolve':
                this.MODAL_CONTAINER_EL.addClass('btn-resolve');
                break;
        }

        if($modalTriggerBtn.hasClass('btn-resolve') ) {
            if($modalTriggerBtn.hasClass('btn-pending') ) {
                this.MODAL_CONTAINER_EL.addClass('btn-pending');
            } else if($modalTriggerBtn.hasClass('btn-monitoring') ) {
                this.MODAL_CONTAINER_EL.addClass('btn-monitoring');
            } else {
                this.MODAL_CONTAINER_EL.addClass('btn-resolve');
            }
        }
    }
}
