import $ from 'jquery';
import {parseJSON} from "./parser_utilities";

/**
 * Used to make ajax request asynchronously useful for request chaining via Promise.all
 * @param ajaxOpts the normal options passed into the ajax object at construction
 * @returns {Promise<any>}
 * @function
 */
export function AjaxPromise(ajaxOpts) {
    return new Promise((resolve, reject) => {
        AjaxSync(ajaxOpts, {
            done: resolve,
            error: reject,
        });
    });
}

/**
 * Used for normal calls to Ajax that don't require being a promise
 * @param ajaxOpts the normal options passed into the ajax object at construction
 * @param callbacks an object of optional callbacks (done, error, always)
 * @reutrns calls the callbacks provided rather than returning anything itself
 * @function
 */
export function AjaxSync(ajaxOpts, callbacks) {
    if(window.sentry_enabled) {
        const sentryTraceParent = $('meta[name="sentry-trace"]').attr('content');
        const sentryBaggage = $('meta[name="baggage"]').attr('content');
        $.ajaxSetup({
            headers: {
                'sentry-trace': sentryTraceParent,
                'baggage': sentryBaggage,
            }
        });
    }

    app.AJAX = true;
    $.ajax({
        ...ajaxOpts,
        ... {
            converters: {
                'text json': (res) => parseJSON(res, true),
                'application json': (res) => parseJSON(res, true),
            }
        }
    }).done((...res) => {
        if(callbacks && callbacks.done)
            if (typeof callbacks.done === "function") {
                callbacks.done(...res);
            } else if (typeof callbacks.done === 'object') {
                for (const f of callbacks.done) {
                    f(...res)
                }
            }
    }).fail((...err) => {
        console.warn(`Ajax error When making request.`, ...err);
        if(callbacks && callbacks.error && typeof callbacks.error === "function") {
            callbacks.error(...err);
        }
    }).always((...res) => {
        app.AJAX = false;
        if(callbacks && callbacks.always && typeof callbacks.always === "function") {
            callbacks.always(...res);
        }
    });
}
