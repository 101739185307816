import { ModalBase } from "../modal_base";

export class ListSignOffModal extends ModalBase {
    constructor() {
        super('list_signoff_modal');
    }

    setupModalView($modalTriggerBtn, additionalData) {
        if (additionalData.repair) {
            this.MODAL_CONTAINER_EL.find('#signoff-repair').show();
        } else {
            this.MODAL_CONTAINER_EL.find('#signoff-repair').hide();
        }
    }
}