/**
 * Used to parse strings to JSON
 * @param {string} res the Text to parse to a JS object from JSON
 * @param {boolean} blankOnError If you need a blank object to be returned on parse errors
 * @returns {{}|undefined|any}
 */
export function parseJSON(res, blankOnError = false) {
    try {
        return JSON.parse(res);
    } catch(err) {
        return blankOnError ? {} : undefined;
    }
}
