export class ModalBase {
    MODAL_ID;
    MODAL_CONTAINER_EL;
    DEFAULT_MODAL_CONTENT;

    constructor(modalId) {
        if(!modalId) {
            console.warn('No modal ID provided.');
            return;
        }

        this.MODAL_ID = modalId;
        this.MODAL_CONTAINER_EL = $(`#${this.MODAL_ID} .modal__container`);
        if(!this.MODAL_CONTAINER_EL || this.MODAL_CONTAINER_EL.length === 0) {
            console.warn(`Unable to fetch modal element for modal ${this.MODAL_ID}`);
            return;
        }

        this.DEFAULT_MODAL_CONTENT = this.MODAL_CONTAINER_EL.html();
        window.dispatchEvent(new CustomEvent('RegisterModal', {
            detail: { id: this.MODAL_ID, instance: this }
        }));
    }

    setupDOM($modalTriggerBtn, additionalData) {}
    setupModalView($modalTriggerBtn, additionalData) {}
    setupModalEvents($modalTriggerBtn, additionalData) {
        this.MODAL_CONTAINER_EL.find('.modal__btn-primary').unbind('click').on('click', (e) => {
            e.preventDefault();
            e.stopPropagation();
            const el = $(e.currentTarget);
            let data = additionalData;
            if(el.data('btn-action')) {
                data.modalClickedAction = el.data('btn-action');
            }

            const event = new CustomEvent('ModalConfirm', {
                detail: data
            });
            event.modalId = this.MODAL_ID;
            event.modalTriggerButton = $modalTriggerBtn;
            window.dispatchEvent(event);
        });
        this.MODAL_CONTAINER_EL.find('input').off('keydown').on('keydown', (e) => {
            if(e.keyCode === 13) {
               this.MODAL_CONTAINER_EL.find('.modal__btn-primary').trigger('click');
            }
        });
    }

    resetModalView() {
        this.MODAL_CONTAINER_EL.html(this.DEFAULT_MODAL_CONTENT);
    }

    saveModalView() {
        this.DEFAULT_MODAL_CONTENT = this.MODAL_CONTAINER_EL.html();
    }

    async onClose(message) {
        await this.resetModalView();

        if(message) {
            alert(message);
        }

        window.dispatchEvent(new CustomEvent('ModalClosed', {
            modalId: this.MODAL_ID,
        }));
    }

    async show($modalTriggerBtn, additionalData) {
        try {
            await this.setupDOM($modalTriggerBtn, additionalData);
            await this.setupModalEvents($modalTriggerBtn, additionalData);
            const shouldOpen = await this.setupModalView($modalTriggerBtn, additionalData);
            if(shouldOpen === false) {
                return;
            }

            if(additionalData.text) {
                this.MODAL_CONTAINER_EL.find('.modal-text').text(additionalData.text);
            }

            if(additionalData.text2) {
                this.MODAL_CONTAINER_EL.find('.modal-text2').text(additionalData.text2);
            }

            if(additionalData.text3) {
                this.MODAL_CONTAINER_EL.find('.modal-text3').text(additionalData.text3);
            }

            const $modal = this.MODAL_CONTAINER_EL;
            $.each(($modalTriggerBtn.attr('data-input')) ?  $modalTriggerBtn.attr('data-input').split(',') : [], (k,input) => {
                $modal.find(`#remodal_confirm_${input}_container`).show();
            });

            MicroModal.show(this.MODAL_ID, {
                disableScroll: true,
                onClose: () => this.onClose()
            });
        } catch (err) {
            console.warn('Unable to display modal.', err);
        }
    }

    async hide(message) {
        MicroModal.close(this.MODAL_ID);
        await this.onClose(message);
    }
}
