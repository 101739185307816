import { ModalBase } from "./modal_base";
import moment from 'moment';
import {AjaxPromise} from "../shared/ajax_utilities";

export class MsgInboxModal extends ModalBase {
    constructor() {
        super('msg_inbox');
    }

    async setupModalView($modalTriggerBtn, additionalData) {
        try {
            const res = await AjaxPromise({
                url: `${app.CACHE.URL_AJAX}message_inbox/get?fulldata=1&id=${$modalTriggerBtn.attr('data-message-id')}&method=single_message`,
                method: 'GET',
            });

            if(res.status !== 'success' || !res.data) {
                console.warn('Error fetching message content.', res);
                return;
            }

            const data = res.data;
            this.MODAL_CONTAINER_EL.attr('data-message-id', data.id);
            this.MODAL_CONTAINER_EL.find('#message-modal-title span').text(data.subject);
            this.MODAL_CONTAINER_EL.find('#message-text').html(data.message);
            this.MODAL_CONTAINER_EL.find('#date_sent').html(data.date_sent && data.date_sent.length > 0  ? `<b>Date Sent:</b> ${moment(data.date_sent).format('DD/MM/YYYY HH:mm')}` : '');
            this.MODAL_CONTAINER_EL.find('#date_read').html(data.date_read && data.date_read.length > 0 ? `<b>Date Read:</b> ${moment(data.date_read).format('DD/MM/YYYY HH:mm')}` : '');
            this.MODAL_CONTAINER_EL.find('#date_ack').html(data.date_accept && data.date_accept.length > 0 ? `<b>Date Acknowledged:</b> ${moment(data.date_accept).format('DD/MM/YYYY HH:mm')}` : '');
            let documents = data.files;
            if(documents && documents.length > 0) {
                this.MODAL_CONTAINER_EL.find('.documents-wrapper').show();
                documents = documents.split(',');
                this.MODAL_CONTAINER_EL.find('.documents').html('');
                documents.forEach((document) => {
                    const documentSplit = document.split('$$$$$');
                    this.MODAL_CONTAINER_EL.find('.documents')
                        .append(`<li><a href="${app.CACHE.TLD}admin/download/doc/${documentSplit[1]}">${documentSplit[0]}</a></li>`);
                });
            } else {
                this.MODAL_CONTAINER_EL.find('.documents-wrapper').hide();
            }

            if(!data.is_read) {
                await this.readMessage($modalTriggerBtn);
            }

            if(!data.needs_accept || data.is_accept) {
                this.MODAL_CONTAINER_EL.find('.modal__btn-primary').unbind('click').hide();
                return;
            }

            this.MODAL_CONTAINER_EL.find('.modal__btn-primary').show();
        } catch(err) {
            console.warn('Error opening message modal', err);
        }
    }

    setupModalEvents($modalTriggerBtn, additionalData) {
        this.MODAL_CONTAINER_EL.find('.modal__btn-primary').unbind('click').on('click', async (e) => {
            e.preventDefault();
            e.stopPropagation();
            try {
                const res = await AjaxPromise({
                    url: `${app.CACHE.URL_AJAX}message_inbox/edit`,
                    method: 'POST',
                    data: {
                        action: 'acknowledge',
                        messageId: additionalData.messageId,
                    }
                });

                if(res.status !== 'success') {
                    console.warn('Unable to acknowledge message.', res);
                    return;
                }

                app.DOM.content.find(`.inbox_table #msg_inbox_${this.MODAL_CONTAINER_EL.attr('data-message-id')} .col_is_accept`)
                    .html('<i class="fa fa-check green f-16"></i>');
                app.DOM.content.find(`.inbox_table #msg_inbox_${this.MODAL_CONTAINER_EL.attr('data-message-id')}`).removeClass('unack');
                const elClone = app.DOM.content.find(`.inbox_table #msg_inbox_${this.MODAL_CONTAINER_EL.attr('data-message-id')}`).clone();
                app.DOM.content.find(`.inbox_table #msg_inbox_${this.MODAL_CONTAINER_EL.attr('data-message-id')}`).remove();
                elClone.prependTo('#read-messages-tbl');

                app.checkUnreadMessages();
                window.location.reload();
            } catch(err) {
                console.warn('Error acknowledging message.', err);
            }
        });
        this.MODAL_CONTAINER_EL.find('input').off('keydown').on('keydown', (e) => {
            if(e.keyCode === 13) {
                this.MODAL_CONTAINER_EL.find('.modal__btn-primary').trigger('click');
            }
        });
    }

    async readMessage(el) {
        try {
            const res = await AjaxPromise({
                url: `${app.CACHE.URL_AJAX}message_inbox/edit`,
                method: 'POST',
                data: {
                    action: 'read',
                    messageId: el.attr('data-message-id'),
                }
            });

            if(res.status !== 'success') {
                return;
            }

            app.checkUnreadMessages(false);
            app.DOM.content.find(`.inbox_table #msg_inbox_${el.attr('data-message-id')}`).removeClass('unread');
            app.DOM.content.find(`.inbox_table #msg_inbox_${el.attr('data-message-id')} .col_is_read`).html('<i class="fa fa-check green f-16"></i>');
        } catch {}
    }
}
