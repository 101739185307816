import $ from 'jquery';
import { ModalBase } from "./modal_base";
import {AjaxSync} from "../shared/ajax_utilities";
import {modalGeneric} from "../shared/common";

export class DriverCheckManageModal extends ModalBase {

    constructor() {
        super('drivercheck_manage_modal');
    }

    setupModalView($modalTriggerBtn, additionalData) {
        this.MODAL_CONTAINER_EL.find('#count').text(additionalData.e_consent.length);
    }

    setupModalEvents($modalTriggerBtn, additionalData) {
        this.MODAL_CONTAINER_EL.find('.modal__btn-primary').unbind('click').on('click', (e) => {
            const el = $(e.currentTarget);
            additionalData.type = el.data('type');
            $modalTriggerBtn.addClass('ajax');

            AjaxSync({
                method: 'POST',
                url: `${app.CACHE.URL_AJAX_DRIVERLOOKUP}consent_mass`,
                data: additionalData
            }, {
                done: (res) => {
                    if( res.data.success && res.data.failed ) {
                        const type = additionalData.type === 'app' ? 'via the app' : 'email';
                        const msgSuccess = ( Object.keys(res.data.success).length ) ? `<p class="b green">You have successfully sent E-Declarations to ${Object.keys(res.data.success).length} driver(s), please allow up to 5 minutes for the driver to receive the E-Declaration ${type}</p>` : '';
                        let msgError = ( Object.keys(res.data.failed).length ) ? `<p><span class="red b">There were error(s) sending E-Declarations to the following driver(s)</span>` : '';

                        if( msgError ) {
                            $.each(res.data.failed, (k,v) => {
                                msgError += `<br><span class="b">${v.name}:</span>`;
                                $.each(v.errors, (kk, vv) => {
                                    msgError += `<br>- ${vv}<br>`;
                                });
                            });

                            // remove last br
                            msgError = `${msgError.slice(0, -4)}</p>`;
                        }

                        modalGeneric('Licence Check E-Declarations', msgSuccess+msgError);

                        // update timestamps&count
                        const $eligible_trs = app.DOM.content.find('#mass_econsent tr');
                        $.each(res.data.success, function(id, count){
                            const $tr = $eligible_trs.filter(`[data="${id}"]`);
                            $tr.find('.driver_lookup_consent_email').text('Queued');
                            $tr.find('.driver_lookup_consent_emails_sent').text(count);
                        });
                    }

                    $modalTriggerBtn.removeClass('ajax button-primary').addClass('button-grey');

                    const $eligible_inputs = app.DOM.content.find('#mass_econsent input').not('.eligible-all');
                    const $econsent_btn_all = app.DOM.content.find('.eligible-all');
                    $eligible_inputs.prop('checked', false);
                    $econsent_btn_all.prop('checked', false);
                }
            });
        });
    }
}