import { ModalBase } from "../modal_base";
import { TachoMissingMileage_bulk_comment, TachoMissingMileage_delete_comment } from '../../list/tco_mis_mil'

export class TcoMisMilCommentDelete extends ModalBase {
    constructor() {
        super('tco_mis_mil_comment_delete');
    }

    setupModalView($modalTriggerBtn, additionalData) {
        if($modalTriggerBtn.hasClass('btn-comment-bulk-delete')) {
            if($modalTriggerBtn.hasClass('button-grey')) {
                alert('Please select a row');
                return false;
            } else if(app.DOM.table.find('tbody tr.no-comment').length > 0) {
                alert(`Cannot bulk delete comments, all selected rows don't have comments`);
                return false;
            }

            TachoMissingMileage_bulk_comment('delete', this.MODAL_CONTAINER_EL);
            return;
        }

        if(!app.DOM.table.find('tbody tr.no-comment').length) {
            alert('Cannot bulk delete comments, all selected rows already have comments');
            return false;
        }

        TachoMissingMileage_delete_comment($modalTriggerBtn, this.MODAL_CONTAINER_EL);
    }
}
